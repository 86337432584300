const Noodl = require('@noodl/noodl-sdk');
const xlsx = require("node-xlsx").default;

const ExcelReaderNode = Noodl.defineNode({
	category:'Utils',
	name:'Excel Reader',
	inputs:{
		ExcelFile:{
			displayName:'ExcelFile',
			type:'*'
		}
	},
	outputs:{
		Result:{
			displayName:'Result',
			type:'array'
		},
		Success:{
			displayName:'Success',
			type:'signal'  
		}
	},
	changed:{
		ExcelFile:function() {
			//this.setOutputs({FullName:this.inputs.FirstName + ' ' + this.inputs.LastName});
		}		
	}, 
	signals:{
		Read: {
			displayName:'Read',
			signal() {
				var _this = this;
				//let fileBuffer = Buffer.from(this.inputs.ExcelFile);
				let reader = new FileReader();

				reader.readAsArrayBuffer(this.inputs.ExcelFile);
				reader.onload = function () {
					//console.log (reader.result);
					let result = xlsx.parse(reader.result, {});
					_this.setOutputs({Result:result});
					_this.sendSignalOnOutput("Success");
					//console.log (result);
				}
				//let result = xlsx.parse(this.inputs.ExcelFile);
				//console.log (this.inputs.ExcelFile);
			}
		}
	}
})

Noodl.defineModule({
    nodes:[
		ExcelReaderNode
    ],
    setup() {
    	//this is called once on startup
    }
});